import { ActionIcon, Anchor, Tooltip } from "@mantine/core";
import React from "react";
import { BrandGithub, BrandSteam, BrandAndroid } from "tabler-icons-react";
import { Links } from "../GameDisplay/data";
import "./IconLink.css"

export default function IconLink({ link, type }: Links) {
    return (
        <ActionIcon radius="xl" variant="transparent" >
            <Tooltip
                label={`${type} link`}
                withArrow
            >
                <Anchor variant="text" href={link} target="_blank">
                    {React.useMemo(() => {
                        switch (type) {
                            case "github":
                                return <BrandGithub />
                            case "steam":
                                return <BrandSteam />
                            case "playstore":
                                return <BrandAndroid />
                            default:
                                break;
                        }
                        return <BrandGithub />
                    }, [link])}
                </Anchor>
            </Tooltip>
        </ActionIcon>
    );
}