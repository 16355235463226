import { ActionIcon, Container, Group, Image, Paper, Stack, Text, Title, Tooltip } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { ArrowBackUp, Eye, EyeOff } from "tabler-icons-react";
import { GameInfo } from "../GameDisplay/data";
import IconLink from "../IconLink/IconLink";
import "./CrossfadeGallery.css";
export interface CrossfadeGalleryProps {
    game: GameInfo;
    onClose?: () => void;
}
export default function CrossfadeGallery({ game, onClose }: CrossfadeGalleryProps) {
    const [detailsOpen, detailsHandler] = useDisclosure(true);

    return (
        <Container className="fade-container">
            <Container style={{ height: "100%", paddingTop: "16px" }}>
                <Paper className={`game-detail-panel ${!detailsOpen ? "game-detail-mini-panel" : ""}`} radius="md" shadow="xl" p="lg">
                    {
                        detailsOpen ?
                            (<Stack spacing={"sm"}>
                                <Group position="apart" noWrap>
                                    <Title order={1}>{game.title}</Title>
                                    <Group spacing={"sm"} noWrap style={{ alignSelf: "flex-start" }}>
                                        <Tooltip
                                            label={`Go Back`}
                                            withArrow
                                        >
                                            <ActionIcon radius="xl" variant="transparent" onClick={onClose}>

                                                <ArrowBackUp />
                                            </ActionIcon>
                                        </Tooltip>
                                        <Tooltip
                                            label={`Hide Details`}
                                            withArrow
                                        >
                                            <ActionIcon radius="xl" variant="transparent" onClick={() => { detailsHandler.close() }}>
                                                <Eye />
                                            </ActionIcon>
                                        </Tooltip>
                                    </Group>

                                </Group>
                                <Group>
                                    {game.links.map((gameLink) => (<IconLink link={gameLink.link} type={gameLink.type} />))}
                                </Group>
                                <Text>{game.description}</Text>
                            </Stack>)
                            :
                            (<Tooltip
                                label={`Show Details`}
                                withArrow
                            >
                                <ActionIcon radius="xl" variant="transparent" onClick={() => { detailsHandler.open() }}>
                                    <EyeOff />
                                </ActionIcon>
                            </Tooltip>)
                    }

                </Paper>
            </Container>
            <Image height={"90vh"} width={"100vw"} className="pic-min" src={`/${game.class}/image-min.png`} />
            <Image id="pic4" height={"90vh"} width={"100vw"} className="pic" src={`/${game.class}/image-4.png`} />
            <Image id="pic3" height={"90vh"} width={"100vw"} className="pic" src={`/${game.class}/image-3.png`} />
            <Image id="pic2" height={"90vh"} width={"100vw"} className="pic" src={`/${game.class}/image-2.png`} />
            <Image id="pic1" height={"90vh"} width={"100vw"} className="pic" src={`/${game.class}/image-1.png`} />

        </Container>
    );
}