import { Center, Container, Group, Stack, Text, Title } from "@mantine/core";
import { useHover, useMediaQuery } from "@mantine/hooks";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import CrossfadeGallery from "../CrossfadeGallery/CrossfadeGallery";
import { Games } from "./data";
import "./GameDisplay.css";

export default function GameDisplay() {
  const [selectedGame, setSelectedGame] = useState(null as number | null)
  const navigate = useNavigate();
  const matches = useMediaQuery('(max-width: 80rem)');
  const { hovered: lastHovered, ref: lastRef } = useHover()


  return (
    <>
      {
        selectedGame === null ?
          (<Group grow style={{ height: "100%" }} spacing={"xs"} noWrap >
            {
              Object.keys(Games).map((game, idx) => (
                <Container key={`showcase-${idx}`} p={0} className={`showcase-container ${matches ? "small" : ""}`} onClick={() => { navigate(`/games/${game}`) }}>
                  <Container className="showcase-name">
                    <Center style={{ width: "100%", height: "100%" }} >
                      <Stack align="center" spacing={0} style={{ padding: "0 20% 0 20%", minHeight: "30%" }}>
                        <Title order={2} align={"center"}>{Games[game].title}</Title>
                        <Title order={3} align={"center"}>{Games[game].year}</Title>
                        {Games[game].genre.map((genre) => (<Text key={`showcase-${idx}-${genre}`}>{genre}</Text>))}
                      </Stack>
                    </Center>
                  </Container>
                  <Container className={`showcase-item ${Games[game].class} blur`} style={{ zIndex: idx }} />
                </Container>
              ))
            }
            <Container key={"showcase-last"} ref={lastRef} p={0} className={`showcase-container ${matches ? "small" : ""}`} onClick={() => { navigate("/terminal") }}>
              <Container className="showcase-name">
                <Center style={{ width: "100%", height: "100%" }} >
                  <Stack align="center" spacing={0} style={{ padding: "0 20% 0 20%", minHeight: "30%" }}>
                    <Title order={2} className={`${lastHovered ? "glitch" : ""}`} align={"center"} data-text="!GHANA">PROJECT</Title>
                    <Title order={3} className={`${lastHovered ? "glitch" : ""}`} data-text="2023">2022</Title>
                    <Text className={`${lastHovered ? "glitch" : ""}`} data-text="Science Fiction">High Fantasy</Text>
                    <Text className={`${lastHovered ? "glitch" : ""}`} data-text="Exploration Parkour">Strategy</Text>
                    <Text className={`${lastHovered ? "glitch" : ""}`} data-text="Mystery">Online Coop</Text>
                  </Stack>
                </Center>
              </Container>
              <Container className={`showcase-item origin blur`} style={{ zIndex: 20 }} />
            </Container>
          </Group >) :
          (<CrossfadeGallery game={Games[selectedGame]} onClose={() => { setSelectedGame(null) }} />)
      }
    </>
  );
}