import { useNavigate } from "react-router-dom";
import DevTerminal from '../components/DevTerminal/DevTerminal';
import "./style.css";


export default function TerminalPage() {
  const navigate = useNavigate();
    return (
      <DevTerminal onExit={()=>{navigate("/")}}/>
    );
}