export interface Links {
    type: string;
    link: string;
}

export interface GameInfo {
    title: string;
    description: string;
    class: string;
    year: string;
    genre: string[];
    links: Links[];
}

export const Games: Record<string, GameInfo> = {
    "unity-td": {
        title: "Unity TD",
        description: "Online tower defence game for two players, where you will build towers to fend off enemy waves. PLant and harvet crops. Towers can be also upgraded.",
        class: "unity-td",
        year: "2018",
        genre: [
            "Tower Defense", "Farming", "FPS"
        ],
        links: [
            { type: "github", link: "https://github.com/Michael23B/Unity-TD-Project" }
        ]
    },
    "skux": {
        title: "Skux Bros",
        description: "Two brawler project where you battle head-to-dead with ak47's.",
        class: "skux",
        year: "2019",
        genre: [
            "Fighter", "Competitive", "Multiplayer"
        ],
        links: [
            { type: "github", link: "https://github.com/JonAlfaro/Skux-Bros" }
        ]
    },
    "piga-gong": {
        title: "Piga Gong",
        description: "A 3D imagination of the classic game pong. You play from the third person perspective of the paddle.",
        class: "piga-gong",
        year: "2020",
        genre: [
            "Sports", "Competitive", "Multiplayer"
        ],
        links: [
            { type: "github", link: "https://github.com/Michael23B/Piga-Gong" }
        ]
    },
    "rigworks": {
        title: "Monkeynetic Rigworks",
        description: "Monkeynetic Rigworks is a third person farming tower defense. Explore the island to collect fruit to sell during the day, and build towers to protect your crystal from the invading skeletons at night.",
        class: "rigworks",
        year: "2020",
        genre: [
            "Tower Defense", "Single Player", "Farming"
        ],
        links: [
            { type: "github", link: "https://github.com/JonAlfaro/Monkeynetic-Rigworks" }
        ]
    },
    "frog-fish": {
        title: "Frog Fish Knife Fish",
        description: "Collect and control the heroes of the realm to defeat the illusive frog fish. THOUSANDS* OF HEROES TO COLLECT. MILLIONS* OF ENEMIES TO BATTLE\nBattle across 60* continents within mythical land Seychelles with infinitely re-playable gameplay. Available free on the Google Play store now while stocks last.",
        class: "frog-fish",
        year: "2021",
        genre: [
            "Clicker", "Mobile", "Single Player"
        ],
        links: [
            { type: "playstore", link: "https://play.google.com/store/apps/details?id=com.MonkeyAndRatSoftware.FrogFishKnifeFish&hl=en&gl=US" }
        ]
    },
    "obama": {
        title: "Obama",
        description: "Obama is a third person action-adventure game with bullet-hell inspired combat. Cleanse an unknown alien planet of the beasts that now inhabit it. Find out who is in command and reveal their ultimate goal.",
        class: "obama",
        year: "2021",
        genre: [
            "Action", "Adventure", "Online Coop"
        ],
        links: [
            { type: "steam", link: "https://store.steampowered.com/app/1763620/Obama/" },
            { type: "github", link: "https://github.com/JonAlfaro/Obama-Public" }
        ]
    }
}