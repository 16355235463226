import { Group, Image, Modal, Title } from "@mantine/core";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./CompanyLogo.css";

export default function CompanyLogo() {
  const navigate = useNavigate();
  const [showImg, setShowImg] = useState(null as null | string);

    return (
      <>
      <Modal
        opened={showImg !== null}
        onClose={() => setShowImg(null)}
        centered
      >
        <Image
        radius="xs"
        src="monkey.png"
        alt="Random unsplash image"
      />

      </Modal>
      <Group id="company-logo" spacing={"xs"} noWrap>
        <Title id="monkey" order={1} onClick={() => {navigate("/")}}> MONKEY </Title>
        <Title id="rat" order={1} onClick={() => {navigate("/")}}> RAT </Title>
        <Title id="software" order={1} onClick={() => {navigate("/")}}> SOFTWARE </Title>
      </Group>
      </>
    );
  }