import { Container } from "@mantine/core";
import Terminal from 'react-bash';
import "./DevTerminal.css";

const structure = {
  '.hidden': {
    contact1: { content: '!@#CORRUPTED>!#!no signs of life present. However locale seems to be have an abundant amount of valuable resources.!@#CORRUPTED>!#!' },
    contact2: { content: 'CORRUPTED' },
    contact3: { content: 'CORRUPTED' },
    contact4: { content: '!@#CORRUPTED>!#!species were hostile on arrival, and all attempts to de-escalate failed. Suggestion: Extermination!@#CORRUPTED>!#!' },
    contact5: { content: 'CORRUPTED' },
    contact6: { content: 'CORRUPTED' },
    contact7: { content: 'CORRUPTED' },
    contact8: { content: '!@#CORRUPTED>!#!diplomatic relationship was established. Trade has been beneficial to both sides.!@#CORRUPTED>!#!' },
    contact9: { content: 'CORRUPTED' },
    contact10: { content: '!@#CORRUPTED>!#!weather was more extreme than anticipated. Crew and submarine lost unknown causes.!@#CORRUPTED>!#!' },
    '.contact0': { content: '!@#CORRUPTED>!#!We are not alone!@#CORRUPTED>!#!' },
    '.contact1X0': { content: '!@#CORRUPTED>!#!STRANGE!@#CORRUPTED>!#!WHITE!@#CORRUPTED>!#!FOG!@#CORRUPTED>!#!' },
    '.contact2X0': { content: '!@#CORRUPTED>!#!Reports of a white whale !@#CORRUPTED>!#! Location unknown !@#CORRUPTED>!#! No survivors.' },
    '.contact3X0': { content: '!@#CORRUPTED>!#! I have seen the inside !@#CORRUPTED>!#! I have seen the inbetween !@#CORRUPTED>!#! But what lies in the outside? !@#CORRUPTED>!#!' },
    '.contact4X0': { content: '!@#CORRUPTED>!#! this is the end. All hope now lies in the ORACLE. --END--' },
    '.contact9X0': { content: 'I have seen the outside' },
  },
  "devDiary": {
    entry1: { content: 'Project scope is larger this time. Substantial R&D required to fine-tune our pipelines.' },
    entry2: { content: 'Basic movement in place. Need to make sure movement is fun and speedrun friendly.' },
    entry3: { content: 'Skeleton of story and characters has been written. Looking forward to connecting Obama to this game.' },
    entry4: { content: 'Designing classes and races for the game. First dungeon finished. Need to think of a name for the game.' },
    entry5: { content: 'Developing iteration 4 of game world. Overhaul to lighting and shaders. Should think about working on a trailer soon.' },
    entry6: { content: 'Writing script for trailer, and started working on puzzles.' },
  }
};

export default function DevTerminal(props) {
  const { onExit } = props
  const extensions = {
    exit: {
      exec: ({ structure, history, cwd }) => {
        if(onExit) {
          onExit()
        }

        return {
          structure, cwd,
          history: history.concat({ value: 'Exiting...' }),
        };
      },
    },
  };
  return (
    <Container className='invert' p={0} fluid>
      <Terminal structure={structure} prefix={"admin@dev"} extensions={extensions}/>
    </Container>
  );
}