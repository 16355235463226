import { Container } from "@mantine/core";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import CrossfadeGallery from "../components/CrossfadeGallery/CrossfadeGallery";
import { Games } from "../components/GameDisplay/data";

export default function GamePage() {
    let params = useParams();
    const navigate = useNavigate();
    const selectedGame = React.useMemo(()=>{
        if (params && params.game !== undefined && Games[params.game]) {
            return Games[params.game]
        }

        // default to obama
        return Games["obama"]
    },[params])

    return (
      <Container p={0} style={{height: "100%", maxWidth: "100vw", overflow: "hidden"}}>
              <CrossfadeGallery game={selectedGame} onClose={() => { navigate("/") }} />
      </Container>
    );
  }