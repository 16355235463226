import { Container } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import GameDisplay from "../components/GameDisplay/GameDisplay";

export default function HomePage() {
  const matches = useMediaQuery('(max-width: 80rem)');

  return (
    <Container p={0} style={{ height: matches ? "100%": "100%", minWidth: "100vw", maxWidth: matches ? undefined :"100vw", overflow: matches ? undefined : "hidden" }}>
      <GameDisplay />
    </Container>
  );
}
// style={{ height: "100%", maxWidth: matches ? "100em" : "100vw", minWidth: matches ? "80em" : undefined, overflow: "hidden" }}