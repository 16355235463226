import {
  Anchor,
  AppShell, Burger, Divider, Footer, Group, Header, MantineProvider, MediaQuery, Navbar, Stack, Text, useMantineTheme
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { Outlet, useNavigate } from 'react-router-dom';
import CompanyLogo from './components/CompanyLogo/CompanyLogo';

function App() {
  const theme = useMantineTheme();
  const [navOpen, navHandler] = useDisclosure(false);
  const navigate = useNavigate();

  return (
    <MantineProvider theme={{
      colorScheme: 'dark',
      colors: {
        "dark": ["#C1C2C5", "#A6A7AB", "#909296", "#5c5f66", "#373A40", "#2C2E33", "#25262b", "#101113", "#101113", "#101113"]
      }
    }}
      withGlobalStyles withNormalizeCSS>
      <AppShell
        navbarOffsetBreakpoint="sm"
        asideOffsetBreakpoint="sm"
        padding={0}
        fixed
        navbar={
          <MediaQuery largerThan="sm" styles={{ display: 'none' }}>
            <Navbar p="md" hidden={!navOpen}>
              <Stack>
                <Anchor size="lg" weight={"bold"} onClick={() => { navHandler.close(); navigate("/") }}>
                  <Text color={theme.colors.gray[1]}>GAMES</Text>
                </Anchor>
                <Divider />
                {/* <Anchor size="lg" weight={"bold"} onClick={() => { navHandler.close(); navigate("/about") }}>
                  <Text color={theme.colors.gray[1]}>ABOUT</Text>
                </Anchor>
                <Divider />
                <Anchor size="lg" weight={"bold"} onClick={() => { navHandler.close(); navigate("/contact") }}>
                  <Text color={theme.colors.gray[1]}>CONTACT</Text>
                </Anchor> */}
              </Stack>
            </Navbar>
          </MediaQuery>
        }
        footer={
          <Footer height={30} p={2}>
            <Text align='right' >2022 Monkey Rat Software</Text>
          </Footer>
        }
        header={
          <Header height={70} p="md" >
            <Group position="apart" grow>
              <Group spacing="xs" noWrap>
                <MediaQuery largerThan="sm" styles={{ display: 'none' }}>
                  <Burger
                    opened={navOpen}
                    onClick={() => navHandler.toggle()}
                    size="sm"
                    color={theme.colors.gray[6]}
                    mr="xl"
                  />
                </MediaQuery>
                <CompanyLogo />
              </Group>
              <MediaQuery smallerThan="sm" styles={{ display: 'none' }}>
                <Group position="right" spacing={"xs"}>
                  {/* <Anchor size="md" weight={"bold"} onClick={() => { navigate("/about") }}>
                    <Text color={theme.colors.gray[1]}>ABOUT</Text>
                  </Anchor>
                  <Anchor size="md" weight={"bold"} onClick={() => { navigate("/contact") }}>
                    <Text color={theme.colors.gray[1]}>CONTACT</Text>
                  </Anchor> */}
                </Group>
              </MediaQuery>
            </Group>
          </Header>
        }
      >
        <Outlet />
      </AppShell>
    </MantineProvider>
  );
}

export default App;
